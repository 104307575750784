@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');

:root {
    font-size: 60%;

    --detail-color: #198FE7;
    --main-color: #5C5D5D;
    --clean-color: #fff;
    --dark-color: #06487B;
    --aux-color: #E0E1E2;

    --editar-color: #28a745;
    --remover-color: #dc3545;

    --rosa-color: #c967c4;

    --table-color: #efefef;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html, body, #root {
    min-height: 100vh;
}

body {
    background-color: var(--clean-color);
}

body,
input,
button,
textarea,
select {
    font-weight: 400;
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    color: var(--main-color);
}

.container {
    width: 90vw;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
}

.content {
    min-height: 70%;
    min-height: -webkit-calc(100vh - 239px);
    min-height: -moz-calc(100vh - 239px);
    min-height: calc(100vh - 239px);
}

.logo {
    font-size: 6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
}
.logo .logo-version {
    font-weight: lighter;
    font-size: 1.4rem;
    padding-left: 125px;
}

.nenhum-registro {
    width: 100%;
    text-align: center;
    padding: 15px;
}