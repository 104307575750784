#cadastro-pesquisador {
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
}

.cancelar {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: var(--main-color);
    text-decoration: none;
}
.cancelar:hover {
    text-decoration: underline;
}