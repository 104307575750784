@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap);
.loading {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: var(--clean-color);
    /* opacity: 0.85; */
    align-items: center;
    justify-content: center;
    z-index: 99999999;
}
.loader {
    border: 6px solid var(--detail-color);
    border-top: 6px solid var(--dark-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}
  
@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
:root {
    font-size: 60%;

    --detail-color: #198FE7;
    --main-color: #5C5D5D;
    --clean-color: #fff;
    --dark-color: #06487B;
    --aux-color: #E0E1E2;

    --editar-color: #28a745;
    --remover-color: #dc3545;

    --rosa-color: #c967c4;

    --table-color: #efefef;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html, body, #root {
    min-height: 100vh;
}

body {
    background-color: #fff;
    background-color: var(--clean-color);
}

body,
input,
button,
textarea,
select {
    font-weight: 400;
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    color: #5C5D5D;
    color: var(--main-color);
}

.container {
    width: 90vw;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
}

.content {
    min-height: 70%;
    min-height: calc(100vh - 239px);
}

.logo {
    font-size: 6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
}
.logo .logo-version {
    font-weight: lighter;
    font-size: 1.4rem;
    padding-left: 125px;
}

.nenhum-registro {
    width: 100%;
    text-align: center;
    padding: 15px;
}
.input {
    max-width: 530px;
    width: 100%;
    margin: 0px auto 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 15px;
}
.input label {
    padding: 10px;
    text-align: left;
    width: 100%;
}
.input input {
    width: 100%;
    max-width: 500px;
    border: 1px solid var(--aux-color);
    background: var(--clean-color);
    padding: 15px;
    border-radius: 5px;
}
.input input:focus, .input input:active {
    border: 1px solid var(--main-color);
    
    outline: none;
}

.input-relative {
    position: relative;
}

.button-float {
    position: absolute;
    top: 0px;
    right: 0px;
}
.button-float .button {
    padding-right: 0px;
}
.button-float .button .btn {
    border-radius: 0px 5px 5px 0px;
    padding: 13px;
    font-size: 2.6rem;
    display: flex;
    align-items: center;
}

@media (max-width: 800px) {
    .input { 
        flex-direction: column;
        margin-bottom: 10px;
    }

    .input label {
        position: relative;
        left: auto;
        width: 100%;
        text-align: center;
    }
}
footer#footer {
    width: 100%;
    padding: 40px 0;
    background: var(--dark-color);
    margin-top: 40px;
}

.redes {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.redes .rede {
    width: 40px;
    height: 40px;
    background: var(--clean-color);
    border-radius: 50%;
    margin-right: 10px;
    color: var(--aux-color);
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}
.redes .rede:last-child {
    margin-right: 0px;
}

.redes .rede:hover {
    color: var(--dark-color);
}

footer#footer .text-footer {
    text-align: center;
    width: 100%;
    color: var(--clean-color);
    margin-top: 15px;
    font-size: 1.2rem;
}
.button {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button .btn {
    padding: 15px;
    background: var(--detail-color);
    color: var(--clean-color);
    font-weight: 400;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: .2s;
    text-decoration: none;
}
.button a.btn {
    width: 100%;
    text-align: center;
}

.button.full {
    width: 100%;
}
.button.full .btn {
    width: 100%;
    font-size: 2rem;
}

.btn:hover, .btn:active, .btn:focus {
    background: var(--dark-color);
    border: none;
    outline: none;
}
.alert {
    background: var(--remover-color);
    color: var(--clean-color);
    border-radius: 5px;
    margin: 15px;
    padding: 15px;
}
.alert.success {
    background: var(--editar-color);
}
.alert > div {
    margin-bottom: 5px;
}
.alert > div:last-child {
    margin-bottom: 0px;
}
#login {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
}

.border-top {
    width: 100vw;
    height: 5rem;
    background: var(--detail-color);
}

.login-form {
    margin: 5rem auto;
    width: 370px;
    flex: 1 1;
}

.esqueceu-senha {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: var(--main-color);
    text-decoration: none;
}
.esqueceu-senha:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 767px) {
    .login-form {
        width: 100%;
        padding: 0 20px;
    }
}
#modal-remover {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#modal-remover .content-modal-remover {
    max-width: 500px;
    width: 100%;
    padding: 3rem;
    background: var(--clean-color);
    opacity: 1;
    border-radius: 5px;
    position: relative;
}

#modal-remover .content-modal-remover .close-modal-remover {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
    color: var(--main-color);
    font-size: 3.2rem;
    cursor: pointer;
}

#modal-remover .content-modal-remover .title-remover {
    width: 100%;
    font-size: 2.4rem;
    color: var(--dark-color);
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

#modal-remover .content-modal-remover .align-buttons-remover {
    display: flex;
    flex-direction: row;
}
#modal-remover .content-modal-remover .align-buttons-remover .button {
    flex: 1 1;
}
#login {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;
}

.border-top {
    width: 100vw;
    height: 5rem;
    background: var(--detail-color);
}

.login-form {
    margin: 5rem auto;
    width: 370px;
    flex: 1 1;
}

.logo {
    font-size: 6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
}
.logo .logo-version {
    font-weight: lighter;
    font-size: 1.4rem;
    padding-left: 125px;
}

.esqueceu-senha {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: var(--main-color);
    text-decoration: none;
}
.esqueceu-senha:hover {
    text-decoration: underline;
}

.text-destaque {
    padding: 0 15px;
    margin: 20px 0px 15px;
}

@media only screen and (max-width: 767px) {
    .login-form {
        width: 100%;
        padding: 0 20px;
    }
}
header#header {
    width: 100%;
}

header#header .heightHeader {
    height: 50px;
}


header#header .top-bar {
    height: 50px;
    background: var(--detail-color);
    position: fixed;
    width: 100%;
    z-index: 999;
}

header#header .top-bar .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

header#header .top-bar .logo {
    font-size: 3.5rem;
    color: var(--clean-color);
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0px;
    height: 50px;
}
header#header .top-bar .logo .logo-version {
    padding-left: 5px;
    margin-top: 14px;
    font-size: 1rem;
}

.btn-user {
    height: 40px;
    width: 40px;
    background: var(--clean-color);
    color: var(--detail-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
    margin: 5px 0px;
    border: 2px solid var(--detail-color);
    transition: .2s;
}

.btn-user:hover {
    border-color: var(--dark-color);
}
.dropdown.active {
    display: flex;
}

.dropdown {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    background: var(--clean-color);
    right: 0px;
    padding: 15px;
    border-radius: 0px 0px 5px 5px;
    font-size: 1.6rem;
    border: 1px solid var(--aux-color);
    border-top: 0px;
}
.dropdown a {
    margin-bottom: 10px;
    color: var(--main-color);
    text-decoration: none;
}
.dropdown a:hover {
    text-decoration: underline;
}
.dropdown a:last-child {
    margin-bottom: 0px;
}
header#top-page {
    width: 100%;
    padding: 40px 0;
    position: relative;
}
header#top-page h1 {
    text-align: center;
    font-size: 4rem;
}
header#top-page h1 small {
    font-size: 1.6rem;
    display: block;
    font-weight: lighter;
}

header#top-page .back-icon, header#top-page .add-icon, header#top-page h1 i {
    width: 35px;
    height: 35px;
    border: 3px solid var(--detail-color);
    color: var(--detail-color);
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    margin-top: 7px;
    padding: 3px;
}
header#top-page .back-icon:hover, header#top-page .add-icon:hover {
    border: 3px solid var(--dark-color);
    color: var(--dark-color);
}

header#top-page .add-icon {
    top: 0px;
    right: 0px;;
}

header#top-page .edit-icon {
    right: 45px;
}
header#top-page .export-icon {
    right: 90px;
    cursor: pointer;
}

header#top-page h1 i {
    display: flex;
    position: relative;
    top: auto;
    left: auto;
    padding: 10px;
    margin: 0 auto;
    width: 50px;
    height: 50px;
}

header#top-page button.add-icon {
    cursor: pointer;
    background: var(--clean-color);
}
header#top-page button.add-icon:active, header#top-page button.add-icon:focus {
    outline: 0px;
}

@media only screen and (max-width: 768px) {
    header#top-page h1 {
        font-size: 2.4rem;
    }
}
.list .list-hrow {
    color: var(--dark-color);
    
}
.list .list-hrow .list-item {
    font-weight: bold;
}

.list .list-hrow, .list .list-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 15px 15px;
}

.list .list-body:nth-child(even) {
    background: var(--aux-color);
}

.list-item:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.btn-list {
    padding: 7px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid var(--dark-color);
    background: var(--detail-color);
    color: var(--clean-color);
    text-decoration: none;
    transition: .2s;
}
.btn-list:last-child {
    margin-right: 0px;;
}

.btn-list.btn-editar {
    border: 1px solid var(--editar-color);
    background: var(--editar-color);
}
.btn-list.btn-editar:hover {
    background: transparent;
    color: var(--editar-color);
}

.btn-list.btn-remover {
    border: 1px solid var(--remover-color);
    background: var(--remover-color);
    width: auto;
    cursor: pointer;
}
.btn-list.btn-remover:hover {
    background: transparent;
    color: var(--remover-color);
}

@media only screen and (max-width: 768px) {
    .list .list-hrow, .list .list-body {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
    .list-item {
        flex: 1 1;
        margin-bottom: 10px;
    }
}
.input select {
    width: 100%;
    max-width: 500px;
    border: 1px solid var(--aux-color);
    background: transparent;
    padding: 15px;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    z-index: 2;
}
.input select:focus, .input select:active {
    border: 1px solid var(--main-color);
    
    outline: none;
}

.input .select-icon {
    position: absolute;
    right: 20px;
    top: 27px;
    height: 20px;
    margin-top: -10px;
    width: 24px;
    z-index: 1;
}
.input .select-icon svg {
    width: 100%;
    height: 100%;
}

.input .input {
    padding: 0px;
    margin-top: 5px;
}

@media (max-width: 800px) {
    .input {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .input label {
        position: relative;
        left: auto;
        width: 100%;
        text-align: center;
    }
}
#cadastro-pesquisador {
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
}

.cancelar {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: var(--main-color);
    text-decoration: none;
}
.cancelar:hover {
    text-decoration: underline;
}
.input textarea {
    width: 100%;
    max-width: 500px;
    border: 1px solid var(--aux-color);
    background: var(--clean-color);
    padding: 15px;
    border-radius: 5px;
}
.input textarea:focus, .input textarea:active {
    border: 1px solid var(--main-color);
    
    outline: none;
}
#cadastro-paciente {
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
}
#list-pacientes .list {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

#list-pacientes .list .paciente-body {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid var(--aux-color);
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    position: relative;
}
#list-pacientes .list .paciente-body .icon-paciente {
    width: 60px;
    height: 60px;
    border: 3px solid var(--detail-color);
    border-radius: 50%;
    margin-right: 15px;
    color: var(--detail-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8rem;
}
#list-pacientes .list .paciente-body .icon-paciente.feminino {
    border: 3px solid var(--rosa-color);
    color: var(--rosa-color);
}
#list-pacientes .list .paciente-body .nome-paciente {
    font-size: 2rem;
    color: var(--main-color);
    text-decoration: none;
    transition: .2s;
}

#list-pacientes .list .paciente-body .nome-paciente i {
    display: none;
    margin-left: 5px;
    font-size: 1.8rem;
}
#list-pacientes .list .paciente-body .nome-paciente:hover i {
    display: inline;
}

#list-pacientes .list .paciente-body .btn-remover-paciente {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--remover-color);
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}
#list-pacientes .list .paciente-body .btn-remover-paciente:hover {
    background: var(--remover-color);
    color: var(--clean-color);
}
#list-pacientes .list .paciente-body .btn-remover-paciente:active,
#list-pacientes .list .paciente-body .btn-remover-paciente:focus {
    outline: none;
}

#list-pacientes .list .alert {
    margin: 15px 0;
}

@media only screen and (max-width: 768px) {
    
}
.dados-teste {
    font-size: 1.4rem;
}
.status-teste {
    margin: 15px;
    border: 1px solid var(--aux-color);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.status-teste .modulo {
    font-size: 2.2rem;
    flex-grow: 1;
}

.status-teste .icon-status {
    width: 50px;
    height: 50px;
    border: 3px solid var(--remover-color);
    font-size: 2.5rem;
    color: var(--remover-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 50%;
    font-weight: bold;
}
.status-teste .icon-status.complete {
    border: 3px solid var(--editar-color);
    color: var(--editar-color);
}
.status-teste .btn-ver {
    padding: 7px;
    color: var(--detail-color);
    font-size: 2.2rem;
    border-radius: 5px;
    display: flex;
    align-items: center;    
}
.status-teste .btn-ver:hover {
    background: var(--aux-color);
}

.loading-module {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: 1s looploading 0s infinite linear;
            animation: 1s looploading 0s infinite linear;
}
@-webkit-keyframes looploading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
@keyframes looploading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
#salvar-observacao {
    max-width: 500px;
    width: 100%;
    margin: 0px auto;
}

.align-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.align-buttons .button {
    flex-grow: 1; 
}
.align-buttons .button .btn {
    width: 100%;
}

.button .btn {
    position: relative;
}
.button .btn .loading-module {
    position: absolute;
    top: 0px;
}
.diagnostico-paciente {
    margin-top: 30px;
}

.diagnostico-paciente .diagnostico-title {
    margin-bottom: 20px;
    font-size: 2.8rem;
    font-weight: bold;
}

.diagnostico-list {
    display: grid;
    grid-template-columns: [first] 160px [second] auto;
    border: 1px solid var(--aux-color);
    border-bottom: 0px;
}
.diagnostico-list:last-child {
    border-bottom: 1px solid var(--aux-color);
}

.diagnostico-list .column-1, .diagnostico-list .column-2 {
    min-height: 40px;
    padding: 10px 15px;
    border-right: 1px solid var(--aux-color);
    display: flex;
    align-items: center;
}
.diagnostico-list .column-2 {
    border-right: 0px;
}

.estimulo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid var(--aux-color);
    border-bottom: 0px;
}
.estimulo-3 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.estimulo > div {
    padding: 10px;
    border-right: 1px solid var(--aux-color);
}
.estimulo > div:last-child {
    border-right: 0px;
}

.estimulo:last-child {
    border-bottom: 1px solid var(--aux-color);
}

.estimulo.processando {
    background: #fffbd8;
}
.estimulo.processado {
    background: #e6ffea;
}
.estimulo.validar {
    background: #ffdfdf;
}

.acoes-estimulo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.edit-estimulo, .play-estimulo {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: var(--editar-color);
    margin-left: 5px;
    color: var(--clean-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.play-estimulo {
    background: var(--detail-color);
}
#model-sound {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#model-sound .content-modal {
    max-width: 500px;
    width: 100%;
    padding: 3rem;
    background: var(--clean-color);
    opacity: 1;
    border-radius: 5px;
    position: relative;
}

#model-sound .content-modal .close-modal {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    top: 5px;
    color: var(--main-color);
    font-size: 3.2rem;
    cursor: pointer;
}

#model-sound .content-modal .modal-sound-title {
    width: 100%;
    font-size: 2.4rem;
    color: var(--dark-color);
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}
#model-sound .content-modal .modal-sound-title i {
    width: 35px;
    height: 35px;
    background: var(--detail-color);
    color: var(--clean-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    margin-right: 10px;
}

#sound-wave {
    position: relative;
    margin: 0 15px;
}

.buttons-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 15px;
}

.buttons-modal .btn-play, .buttons-modal .btn-stop {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
}

.title-modulo {
    font-size: 2.8rem;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: bold;
}

.header-estimulos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    font-weight: bold;
    color: var(--dark-color);
    border: 1px solid var(--aux-color);
    border-bottom: 0px;
}
.header-estimulos.header-estimulos-3 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.header-estimulos > div {
    padding: 10px;
    border-right: 1px solid var(--aux-color);
}
.header-estimulos > div:last-child {
    border-right: 0px;
}

.wavesurfer-handle {
    width: 5px !important;
    background: var(--dark-color) !important;
}
#teste {
    width: 100vw;
    height: 100vh;
}

#teste .tutorial-test {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#teste .tutorial-test .title-test, 
.progresso-test .title-test {
    font-size: 3.8rem;
    font-weight: bold;
    margin-top: 15px;
}

#teste .tutorial-test .desc-test { 
    max-width: 500px;
    width: 100%;
    margin: 15px;
    padding: 0 15px;
    text-align: center;
    font-size: 2.2rem;
}

#teste .tutorial-test .btn-iniciar,
.progresso-test .btn-iniciar { 
    width: 90px;
    height: 90px;
    background: var(--detail-color);
    color: var(--clean-color);
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s;
}

#teste .tutorial-test .btn-iniciar:hover,
.progresso-test .btn-iniciar:hover {
    background: var(--dark-color);
}


#teste .content-test {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#teste .content-test .text {
    font-size: 12rem;
    font-weight: bold;
}
#teste .content-test .circle {
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
}

#teste .content-test .align-buttons {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

#teste .content-test .align-buttons .button {
    padding-right: 0px;
}


.progresso-test {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.progresso-test .progresso {
    padding: 15px;
    border: 1px solid var(--aux-color);
    border-radius: 5px;
}

.progresso-test .progresso .title-progresso {
    text-align: center;
    font-weight: bold;
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
}

.progresso-test .progresso .list-progresso {
    display: grid;
    grid-template-columns: 45px 45px 45px 45px 45px 45px;
}

.progresso-test .progresso .list-progresso .item-progresso {
    background: var(--aux-color);
    margin: 3px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}
.progresso-test .progresso .list-progresso .item-progresso.item-progresso-checked {
    background: var(--detail-color);
    color: var(--clean-color);
}

.sound-wave {
    display: none;
}

@media (max-width: 700px) and (orientation: landscape) { 
    
    .progresso-test .progresso .title-progresso {
        margin-bottom: 0.6rem !important;
    }

    .progresso-test .progresso {
        padding: 5px !important;
    }

    .progresso-test .progresso .list-progresso .item-progresso {
        padding: 0px;
    }

    .progresso-test .title-test {
        font-size: 3rem;
    }

    #teste .tutorial-test .btn-iniciar, .progresso-test .btn-iniciar {
        width: 50px;
        height: 50px;
        font-size: 3rem;
    }

    .progresso-test .button .btn {
        padding: 10px;
    }
}

@media (max-width: 768px) {
    #teste .content-test .text {
        font-size: 5rem;
    }
}
#cadastro-pesquisador {
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
}

.cancelar {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: var(--main-color);
    text-decoration: none;
}
.cancelar:hover {
    text-decoration: underline;
}
